import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          adress
          phone
          mail
          city
        }
      }
    }
  `)
  return (
    <Layout pageTitle="Home">
      <StaticImage
        alt={data.site.siteMetadata.title}
        src="../images/b0.webp"
        className='rounded-lg'
      />
              
      <div className="mt-10">
        <p className="text-primary text-right font-light text-xl md:mr-20 mr-0">„ Inspiracja istnieje, ale musi Cię zastać przy pracy”</p>
        <p className="text-primary text-right font-light italic md:mr-20 mr-0 mb-10">Pablo Picasso</p>
        <p className="textBlock">
          Dobra Marka jest szwalnią z ambicjami wypuszczania na rynek własnych, oryginalnych produktów. Łączymy w cechy profesjonalnej szwalni  z kreatywnością i wyobraźnią.  Szukamy  nowych inspiracji wokół nas a wszystko, to wsłuchując się w potrzeby klientów. Szyjemy i tworzymy różnego rodzaju produkty jednak naszemu sercu najbliżej jest do tych najsłodszych klientów, czyli dzieci i zwierząt.
        </p>
        <p className="textBlock">
          Wielu rodziców małych dzieci przekonało się już, że rzeczy wychodzące spod naszej igły są najwyższej jakości i odpowiadają na potrzeby nawet najbardziej wymagających klientów.
        </p>
        <p className="textBlock">
          Natomiast miłośnicy zwierząt cenią nasze produkty  za niebanalność, estetykę, funkcjonalność, staranność wykonania i trwałość.  Dbamy o to, by miękkie i kolorowe schronienia jak najdłużej cieszyły użytkowników i ich właścicieli. Chcemy, by z nimi każdy dom był przytulniejszy i weselszy. W imieniu kreatywnego, zespołu projektantów i szwaczek zapraszam do współpracy
        </p>
        <p className="text-right md:mr-20 mr-1">
          W imieniu kreatywnego, zespołu projektantów i szwaczek zapraszam do współpracy
        </p>
        <p className="text-right md:mr-20 mr-1 md:mb-20 mb-10">Joanna Pałka prezes „Dobrej Marki”</p>



          <div className="flex items-center textBlock  border-t border-gray-300 pt-10">

            <StaticImage
              alt={data.site.siteMetadata.title}
              src="../images/szowesSM.png"
            />

       
            <div className='p-5 pr-0'>
            Materiały wykonane w ramach projektu "SZOWES-OWES w regionie koszalińskim" realizowanego w ramach Regionalnego Programu Operacyjnego Województwa Zachodniopomorskiego 2014-2020
            </div>
          </div>
          <div className="flex justify-between items-center textBlock ">
          <StaticImage
              alt={data.site.siteMetadata.title}
              src="../images/funduszeEUSM.png"
            />
                        <StaticImage
              alt={data.site.siteMetadata.title}
              src="../images/polskaSM.png"
            />
                        <StaticImage
              alt={data.site.siteMetadata.title}
              src="../images/zachpomSM.png"
            />
                        <StaticImage
              alt={data.site.siteMetadata.title}
              src="../images/EUSM.png"
            />

            

          </div>


      </div>

    </Layout>
  )
}

export default IndexPage